import React from "react";
import {Link} from 'gatsby'
import {PostHead} from './PostHead'

export const Post = ({post}) => {
  const {
    frontmatter: {slug},
  } = post

  return (
    <article>
      <PostHead frontmatter={post.frontmatter}/>

      <Link to={slug}>
        Read More &rarr;
      </Link>
    </article>
  )
}

export default {Post}
