import React from "react"
import {graphql} from "gatsby"
import SiteLayout from "../components/SiteLayout";
import {Post} from "../components/Post";

export default function Template({data, pageContext}) {
  const posts = data.allMdx.edges
  return (
    <SiteLayout>
      <h2 className={`text-2xl pb-4`}>{pageContext.tag}</h2>

      <>
        {posts.map((post) => (
          <Post key={post.node.frontmatter.slug} post={post.node}/>
        ))}
      </>
    </SiteLayout>
  )
}
export const pageQuery = graphql`
  query TagPosts ($tag: [String]!) {
    allMdx(
      filter: { frontmatter: { tags: { in: $tag } } },
      sort: {order: DESC, fields: frontmatter___date}
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title,
            slug,
            date
          }
        }
      }
    }
  }
`
